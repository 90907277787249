import React, { useState } from 'react';
import LeftColumn from './left/left_menu';
import RightColumn from './right/right_board';
import 'bootstrap/dist/css/bootstrap.min.css';


function PublisherDashboard() {
  const [activeMenuItem, setActiveMenuItem] = useState('Dashboard');

  const renderRightColumn = () => {
    switch (activeMenuItem) {
      case 'Dashboard':
        return <RightColumn activeMenuItem={activeMenuItem} />;
      case 'Analytics':
        return ; //Analytics Column Here
      case 'Settings':
        return ; //Settings Column Here
      default:
        return <RightColumn />; // Fallback to 'Dashboard' view
    }
  };

  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
      <div className="col-md-2 d-flex align-items-center justify-content-center" style={{ backgroundColor: '#28cbff', height: '100vh' }}>
        <LeftColumn setActiveMenuItem={setActiveMenuItem} activeMenuItem={activeMenuItem} />
      </div>
      <div className="col-sm-10">
       {renderRightColumn()}
      </div>
      </div>
    </div>
  );
}

export default PublisherDashboard;
