import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';


function PubMenu({ setActiveMenuItem, activeMenuItem }) {
  const menuItems = [
    { name: 'Dashboard', icon: 'bi-house' },
    { name: 'Analytics', icon: 'bi-graph-up' },
    { name: 'Settings', icon: 'bi-gear' }
  ];

  return (
    <div className="list-group">
      {menuItems.map((item) => (
        <PubMenuItem
          key={item.name}
          item={item}
          active={activeMenuItem === item.name}
          onClick={() => setActiveMenuItem(item.name)}
        />
      ))}
    </div>
  );
}

function PubMenuItem({ item, active, onClick }) {
  return (
    <button
      className={`list-group-item list-group-item-action d-flex align-items-center ${active ? 'active' : ''}`}
      onClick={onClick}
    >
      <i className={`${item.icon} me-2`}></i> {/* Add the icon with margin */}
      {item.name}
    </button>
  );
}

export default PubMenu;